<template>
  <div>
    <button @click="prevTab" :disabled="currentIndex === 0">上一个</button>
    <button @click="nextTab" :disabled="currentIndex === tabs.length - 1">
      下一个
    </button>
    <div class="tab-container">
      <div
        v-for="tab in visibleTabs"
        :key="tab.id"
        :class="['tab', { active: currentIndex === tab.id - 1 }]"
        @click="selectTab(tab.id - 1)"
      >
        {{ tab.title }}
      </div>
    </div>
    <transition name="fade" mode="out-in">
    <div class="tab-content" v-if="currentTab" :key="currentTab.id">
      {{ currentTab.content }}
    </div>
  </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { id: 1, title: "Tab 1", content: "Content 1" },
        { id: 2, title: "Tab 2", content: "Content 2" },
        { id: 3, title: "Tab 3", content: "Content 3" },
        { id: 4, title: "Tab 4", content: "Content 4" },
        { id: 5, title: "Tab 5", content: "Content 5" },
        { id: 6, title: "Tab 6", content: "Content 6" },
        { id: 7, title: "Tab 7", content: "Content 7" },
        { id: 8, title: "Tab 8", content: "Content 8" },
        { id: 9, title: "Tab 9", content: "Content 9" },
      ],
      currentIndex: 0,

      visibleTabs: [],
    };
  },
  computed: {
    currentTab() {
      return this.tabs[this.currentIndex];
    },
  },
  methods: {
    prevTab() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateVisibleTabs();
      }
    },
    nextTab() {
      if (this.currentIndex < this.tabs.length - 1) {
        this.currentIndex++;
        this.updateVisibleTabs();
      }
    },
    selectTab(index) {
      this.currentIndex = index;
      this.updateVisibleTabs();
    },
    updateVisibleTabs() {
      const startIndex = Math.max(0, this.currentIndex - 4);
      const endIndex = Math.min(startIndex + 4, this.tabs.length - 1);
      this.visibleTabs = this.tabs.slice(startIndex, endIndex + 1);
    },
  },
  mounted() {
    this.updateVisibleTabs();
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.tab-container {
  width: 500px;
  display: flex;
  justify-content: space-between;
}
.tab {
  padding: 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
}
.tab-content {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 10px;
}
.active {
  background: red;
}
</style>
