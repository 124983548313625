import { render, staticRenderFns } from "./hahafour.vue?vue&type=template&id=99b95460&scoped=true&"
import script from "./hahafour.vue?vue&type=script&lang=js&"
export * from "./hahafour.vue?vue&type=script&lang=js&"
import style0 from "./hahafour.vue?vue&type=style&index=0&id=99b95460&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99b95460",
  null
  
)

export default component.exports